//prep the doc---------------------------------------

* {
    box-sizing: border-box;
}

body {
    margin: 0;
    padding: 0;
    background-color: white;
}

p, a {
    font-family: 'Lato', sans-serif;
}

h2, h2, h3, h4, h5, h6 {
    font-family: 'Open Sans', sans-serif;
}

@import "typography";
@import "colors";
@import "navbar";
@import "footer";
@import "cta";
@import "buttons";

//hero section and button styles-------------------------

section.hero {
    background: $index-hero-background;
    position: relative;
    height: 70vh;
    min-height: 350px;
    display: flex;
    flex-direction: row;
    margin-bottom: 0.5em;
    h1 {
        font-family: Lora, sans-serif;
        max-width: 800px;
        span {
            background-color: black;
            color: white;
        }
    }
    .hero_description {
        margin: 1em;
        margin-top: 0.8em;
    }
    p {
        max-width: 600px;
    }
}

section.hero:after {
    background-color:  $index-hero-after-psuedo; 
    bottom: 0;
    content: '';
    display: block;
    height: 50%;
    left: 0;
    position: absolute;
    right: 0;
    transform: skewY(4deg);
    transform-origin: 0%;
    z-index: -1;
  }
//stats section---------------------------------------

section.statistics {

    .numbers {
        padding: 0.5rem 0 1rem 0;
        background-color: $stats-callout-background-color;
        position: relative;
        top: -3.5rem;
        border-radius: 25px;
        width: 45%;
        min-width: 320px;
        margin: 0 auto;
        margin-bottom: -3.5rem;
        box-shadow: $stats-callout-shadow;
    }
    span {
        margin-top: 1.5em;
        margin-bottom: 0;
        display: block;
        text-align: center;
        font-size: 40px;
        text-decoration: bold;
        font-family: 'Old Standard TT', serif;
    }
    p {
        text-align: center;
    }
}


//services section---------------------------------------

.services_heading {
    text-align: center;
    padding-top: 1em;
    z-index: -2;
}

.services_body {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 1em;
    justify-content: space-evenly;
    .service_container{
        width: 1fr;
        border-radius: 25px;
        box-shadow: $services-box-shadow;
        background-color: $services-box-background-color;
        color: $services-box-text-color;
        margin: 1em 0 1.5em 0;
        padding: 1em;
        text-align: center;
        max-width: 350px;
        i {
            font-size: 30px;
        }
        h3 {
            margin: 0.8em 0 1.2em 0;
        }
    }
}

//flex description and pics section-------------

.info {
    display: flex;
    flex-flow: row wrap;
    padding-bottom: 2em;
    justify-content: center;
    .info_image {
        align-self: flex-start;
        margin: 1em;
        min-width: calc(100% - 2em);
        height: 300px;
        background-color: $info-image-background;
    }
    .info_image#webdev_info {
        border-radius: 50% 5px 5px 50%;
    }
    .info_image#presence_info {
        border-radius: 5px 50% 50% 5px;
    }

    img {
        width: auto;
        height: auto;
        max-width: 100%;
        height: 100%;
        object-fit: contain;
    }
    .info_text {
        max-width: 500px;
        margin: 1em;
    }
}
//last CTA in the homepage----------------------

.contact_us {
    padding: 2em;
    h2 {
        margin-top: 0;
        padding-top: 1em;
    }
    //background-color: #ffce49;
    text-align: center;
    min-height: 200px;
    .contact_us_text {
        margin: 1em;
        margin-top: 0;
    }
}

//media queries for larger screen sizes-------
@media (min-width: 830px) {
    .hero_description_text h1 {
        font-size: 70px;
    }
}

@media (min-width: 700px) {

    section.hero:after {
        transform: skewY(3deg);
    }

    .services_heading {
        padding-top: 1em;
    }

    section#web{
        position: relative;
    }
    .info {
        padding: 1em 0;
        .info_image {
            min-width: 300px;
            width: calc(50% - 2em);
            align-self: flex-end;
        }
        .info_text {
            max-width: calc(50% - 2em);
        }
    }
    #web {
        flex-direction: row-reverse;
    }
}

@media (max-width: 600px) {
    .image_section{
        display: none;
    }
    section.statistics {
        p {margin-top: 0;}
        span {margin-top: 1em;}
    }
}

@media (max-height: 710px) {
    section.hero {
        min-height: 425px;
    }
    section.statistics .numbers {
        top: -0.5rem;
        margin-bottom: -0.5rem;
    }
}