// Modern Scale for Web Typography
// Ref: http://typecast.com/blog/a-more-modern-scale-for-web-typography
// -----------------------------------------------------------------------------
// Mobile
// -----------------------------------------------------------------------------
// Elem | Font | Line
// -----------------------------------------------------------------------------
// Body | 16px | 20px
// h1   | 32px | 40px
// h2   | 26px | 30px
// h3   | 22px | 25px
// h4   | 18px | 20px
// h5   | 16px | 20px
// h6   | 14px | 17.5
// BQ   | 20px | 25px

html {
    font-size: 18px;
    line-height: 1.5;
  }
  body,
  caption,
  th, td,
  input, textarea, select, option,
  legend, fieldset,
  h1, h2, h3, h4, h5, h6 {
    font-size-adjust: 0.5;
  }
  body {
    font-size: 1rem;
    line-height: 1.5;
  }
  h1 {
    font-size: 2rem;
    line-height: 1.25;
  }
  h2 {
    font-size: 1.67rem;
    line-height: 1.15;
  }
  h3 {
    font-size: 1.375rem;
    line-height: 1.13636364;
  }
  h4 {
    font-size: 1.125rem;
    line-height: 1.11111111;
  }
  h5 {
    font-size: 1rem;
    line-height: 1.25;
  }
  h6 {
    font-size: 0.75rem;
    line-height: 1.25;
  }
  blockquote {
    font-size: 1.25rem;
    line-height: 1.25;
  }
  
  // Large Tablet
  // -----------------------------------------------------------------------------
  // Elem | Font | Line
  // -----------------------------------------------------------------------------
  // Body | 16px | 22px
  // h1   | 40px | 45px
  // h2   | 32px | 40px
  // h3   | 24px | 30px
  // h4   | 18px | 22px
  // h5   | 16px | 22px
  // h6   | 14px | 19.25px
  // BQ   | 24px | 33px
  
  @media (min-width: 650px) {
    body {
      font-size: 1.12rem;
      line-height: 1.5rem;
    }
    h1 {
      font-size: 2.5rem;
      line-height: 1.125;
    }
    h2 {
      font-size: 2rem;
      line-height: 1.125;
    }
    h3 {
      font-size: 1.5rem;
      line-height: 1.25;
    }
    h4 {
      font-size: 1.125rem;
      line-height: 1.22222222;
    }
    h5 {
      font-size: 1rem;
      line-height: 1.375;
    }
    h6 {
      font-size: 0.75rem;
      line-height: 1.203125;
    }
    blockquote {
      font-size: 1.5rem;
      line-height: 1.375;
    }
  }
  
  // Large Desktop
  // -----------------------------------------------------------------------------
  // Elem | Font | Line
  // -----------------------------------------------------------------------------
  // h1   | 48px | 50px
  // h2   | 36px | 45px
  // h3   | 28px | 35px
  
  @media (min-width: 900px) {
    h1 {
      font-size: 3rem;
      line-height: 1.05;
    }
    h2 {
      font-size: 2.25rem;
      line-height: 1.125;
    }
    h3 {
      font-size: 1.75rem;
      line-height: 1.25;
    }
  }