$button-fill: #abdf75;
$button-hover: #428000;
$shadow-left: -5px 5px 5px rgba(184, 184, 184, 0.877);
$shadow-bottom: 0 10px 15px 0 rgba(0,0,0,.16),0 0 0 1px rgba(0,0,0,.06);
@mixin hover {
    background-color: $button-hover;
    color: white;
    box-shadow: $shadow-bottom;
}

.hero_description {
    .button_wrapper {
        margin-top: 1em;
        float: left;
    }
    .hero_button {
        //border: 4px solid #44ffd1;
        border-radius: 10px;
        color: black;
        background: $button-fill;
        padding: 10px 30px;
        text-decoration: none;
        &:hover {
            @include hover;
        }
    }
}

.button_wrapper {
    text-align: center;
}

.learn_more_button {
    border-radius: 25px;
    background: $button-fill;
    color: black;
    font-weight: lighter;
    font-size: 10pt;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: bold;
    padding: 12px 25px;
    &:hover {
        @include hover;
    }
}

.services_container {
    .button_wrapper {
        margin-top: 1.5em;
        margin-bottom: 1em;;
    }
}